<template>
    <TopNav></TopNav>
		<h1>Vücut Kitle İndeksi Hesaplama</h1>
		<fieldset>
      <legend>Vücut Kitle İndeksi Hesaplama Aracı</legend>
			<label for="kilogram">Kilo:</label> <input type="number" v-model.number="inputKilogram" id="kilogram" step="any" min="0"> <abbr title="kilogram">㎏</abbr>
			<br>
			<label for="centimeters">Boy:</label> <input type="number" v-model.number="inputCentimeter" id="centimeters" step="any" min="0"> <abbr title="santimetre">㎝</abbr>
			<br><br>
			Vücut Kitle İndeksi: <span class="blue">{{ bmiFormatted }} ㎏/㎡</span> <span v-bind:style="{color: currentState.color}">{{ currentState.text }}</span>
		</fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: 'VucutKitleIndeksi',
  data() {
    return {
      inputCentimeter: 0,
      inputKilogram: 0
    }
  },
  components: {
    TopNav
  },
  computed: {
    bmi: function () {
      return (((this.inputKilogram) / Math.pow((this.inputCentimeter / 100), 2)));
    },
    bmiFormatted: function () {
      const formatter = new Intl.NumberFormat('tr-TR', {
        maximumFractionDigits: 1
      });
      let bmiNumber = Number(this.bmi);
      return formatter.format(bmiNumber);
    },
    currentState: function () {
      if (this.bmi < 16) {
        return {"text": "Aşırı Zayıf", "color": "red"};
      } else if (this.bmi < 18.5) {
        return {"text": "Zayıf", "color": "orange"};
      } else if (this.bmi < 25) {
        return {"text": "Normal", "color": "green"};
      } else if (this.bmi < 30) {
        return {"text": "Fazla Kilolu", "color": "orange"};
      } else if (this.bmi < 35) {
        return {"text": "1. Sınıf Obez", "color": "red"};
      } else if (this.bmi < 45) {
        return {"text": "2. Sınıf Obez", "color": "red"};
      } else if (this.bmi >= 45) {
        return {"text": "3. Sınıf Obez", "color": "red"};
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 80px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
